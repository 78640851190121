import React, { Component } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import '../App.css';

class SnippetContainer extends Component {
	generateSnippet = () => {
		let snippet = `<!-- START LEGACY RAVEN --- WARNING: Must have <div id="ravenEventContainer"></div> in the template for this to work! -->
<script type='text/javascript' src='./hamerkop.js'></script>
<script defer type='text/javascript'>
\twindow.LEGACY_RAVEN_SERVER = 'legacy-raven-test.touchsource.com';
\twindow.LEGACY_RAVEN_PROXY_SERVER = 'localhost:3333';
\tfunction initRaven() {
\t\tconst Raven = require('/raven');
\t\tvar raven = new Raven(${this.props.displayID});
\n\t\traven.getBroncoSettings();
\n\t\traven.getParrotConfig(raven.parrotOpts).then((contents) => {
\t\t\tlet configList = [contents];
\t\t\tconsole.log(contents);
\t\t\traven.startParrot(configList);
\t\t}).catch((err) => {
\t\t\tconsole.error(\`Cannot retrieve Parrot Config, halting error: \${err}\`);
\t\t});
\t}
\n\tvar proxyFetched = false;
\tfunction fetchRaven() {
\t\tif (typeof Raven === 'undefined') {
\t\t\tlet p = window.location.protocol;
\t\t\tlet ravenScript = \`$\{p\}//$\{window.LEGACY_RAVEN_SERVER\}/raven/raven.js\`
\t\t\tif (window.LEGACY_RAVEN_PROXY_SERVER) {
\t\t\t\travenScript = \`$\{p\}//$\{window.LEGACY_RAVEN_PROXY_SERVER\}/?url=$\{encodeURIComponent(p + '//' + window.LEGACY_RAVEN_SERVER + '/raven/raven.js')\}&update=1\`
\t\t\t}
\t\t\tconsole.info(ravenScript);\n
\t\t\tlet script = document.createElement('script');
\t\t\tscript.type = 'text/javascript';
\t\t\tscript.src = ravenScript;
\t\t\tscript.onload = initRaven;
\t\t\tscript.onerror = () => {
\t\t\t\tif (!proxyFetched) {
\t\t\t\t\tconsole.warn("WARN: raven.js must be loaded before use...");
\t\t\t\t\tproxyFetched = true;
\t\t\t\t}
\t\t\t\tsetTimeout(() => {
\t\t\t\t\tconsole.error('Raven failed to fetch: ' + ravenScript);
\t\t\t\t\tfetchRaven();
\t\t\t\t}, 5000);
\t\t\t\treturn;
\t\t\t};
\t\t\tdocument.body.appendChild(script);
\t\t} else {
\t\tinitRaven();
\t\t}
\t};
\twindow.addEventListener('load', fetchRaven);
</script>
<!-- END LEGACY RAVEN -->`

		if (this.props.customize) {
			snippet += `
\t\t<div>
\t\t</div>`
		}

		return snippet
	}

	render() {
		let snippet = this.generateSnippet();

		if (this.props.displayID === '') {
			return (
				<SyntaxHighlighter className='snippet' language='javascript' style={monokai}>
					Please enter a display ID...
				</SyntaxHighlighter >
			)
		}

		return (
			<SyntaxHighlighter className='snippet' language='javascript' style={monokai}>
				{snippet}
			</SyntaxHighlighter >
		)
	}
}

export default SnippetContainer;