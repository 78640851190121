import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SnippetContainer from './components/SnippetContainer';
import SnippetForm from './components/SnippetForm';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			repo: 'Raven',
			displayID: '',
			customize: false
		}
	}

	handleRepoChange = (e) => {
		console.log(e.target.value)
		this.setState({
			repo: e.target.value
		});
	}

	handleValueChange = (e) => {
		console.log(e.target.name, e.target.value, e.target.checked, e.target.type)
		let name = e.target.name;
		let value;

		switch (e.target.type) {
			case 'number':
				value = e.target.value;
				console.log(value)
				break;
			case 'checkbox':
				value = e.target.checked;
				break;
			default:
				console.error('Invalid type: ', e.target.type);
				return;
		}

		this.setState({
			[name]: value
		});
	}

	generateForm = () => {
		let fields = [
			{
				type: 'number',
				name: 'displayID',
				friendlyName: 'Display ID'
			}
		];

		return (
			<SnippetForm
				fields={fields}
				onChange={this.handleValueChange}
			/>
		);
	}

	render() {
		let form = this.generateForm();

		return (
			<div>
				<header className="App-header">
					<h1 className="App-title">Legacy Snippet Tool</h1>
				</header>
				<Container fluid>
					<Row className="justify-content-md-center m-3">
						<Button
							variant={this.state.repo === 'Raven' ? 'success' : 'info'}
							onClick={this.handleRepoChange}
							value='Raven'
						>
							Raven
						</Button>
					</Row>
					<Row>
						<Col xs={3}>
							{form}
						</Col>
						<Col xs={9}>
							<SnippetContainer
								customize={this.state.customize}
								displayID={this.state.displayID}
								repo={this.state.repo}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	};
}

export default App;
